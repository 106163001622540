"use strict";
var Project = (typeof Project === 'undefined')?{}:Project;

Project.Base = {
	/** @type jQuery "Please wait" DIV */
	$searchDiv					: null,
	/** @type jQuery */
	$searchContainerDiv			: null,
	/** @type jQuery */
	$resultDiv					: null,
	/** @type jQuery */
	$dateSelect					: null,
	/** @type Boolean */
	waitingForSearchResponse	: false,
	/** @type int */
	searchStartTime				: 0,
	/** @type int */
	searchTimeout				: 300000,
	/** @type Boolean */
	firstResponse				: true,
	/** @type jQuery */
	$miniIndicator				: null,
	/** @type int */
	totalCount					: 0,
	/** @type int */
	totalDays					: 0,
	initialize: function() {
		Project.Utils.debug("Base inited!");
		if ($("#crm_search").length)		this.initSearchPanel();

		if ($("#settings_panel").length)	this.initSettings();
	},
	initExports		: function(){
		var self = this;
		$('#crm_search').on('click, ifClicked', '.result-select', function(event){
			$(this).closest('.searched_row').toggleClass('selected');
		});

		$('#export_buttons').on('click', '.export-icon', function(event){
			var cmd		= $(this).data('command'),
				$news	= $('#search_result').find('.result-select:checked'),
				ids		= $news.length?_.pluck($news.toArray(), 'value'):[]
			;

			if (!ids.length) {
				Project.WM.renderError(Project.Messages.get('NO_RESULT_SELECTED'), Project.Messages.get('EXPORT_ERROR'), {
					width: 'auto',
					height: 'auto'
				});
				return;
			} else if (ids.length > 50) {
				Project.WM.renderError(Project.Messages.get('TOO_MANY_RESULTS_SELECTED').replace('%current%', ids.length).replace('%max%', 50), Project.Messages.get('EXPORT_ERROR'), {
					width: 'auto',
					height: 'auto'
				});
				return;
			}

			var docToken = Project.Export.startExport();
			document.location = `${cmd}/${ids.join('|')}?docToken=${docToken}`;
		});
	},

	initSearchPanel	: function(){
		var self= this;

		this.$searchDiv = $("#searchDiv");

/*		$('#source_name').on('change', function() {
			var id = $(this).val();

			if (id === '') {
				$('.mediatypes').show();
			} else {
				$('.mediatypes').hide();
				$.post('/admin/source-type/', { id: id },
					(response) =>
						_.forOwn(response,
							(val) => $('#' + val).parents('.mediatypes').show()
						)
				);
			}
		}).trigger('change');*/

		$('#source_country').on('change', function(){
			var $sources = $('#source_name');
			ajax('base', 'getSourcesByCountry', [ $(this).val() ],
				/**
				 * @param {string} response
				 * @param {Ajax} xmlhttp
				 */
				function(response, xmlhttp){
					$sources.children().remove();
					var sources = _.isObject(response)?response:JSON.parse(response);
					//Project.Utils.debug(sources);

					$(sources).each(function(index, source){
						$sources.append($('<option value="' + source.id + '">' + source.name + '</option>'));
					});
					if($sources.is('.selectpicker')) {
						$sources.selectpicker('refresh');
					}
				}

			);

		}).change();

		var $searchOptions = $('#show_search_options');
		$('#search_option_label').click(function() {
			$searchOptions
				.attr('checked', !$searchOptions.attr('checked'))
				.trigger('change')
			;
		});

		$searchOptions.change(function() {
			$(this).attr('checked')
				?$('.search_options').show()
				:$('.search_options').hide()
			;
		}).trigger('change');

		$('#press').on('change', function(){
			($(this).attr("checked"))
				?$("#dialog_filter_container").show()
				:$("#dialog_filter_container").hide()
			;
		}).change();

		$('[id="submit.1"]').on('click', function(event){
			self.startAjaxSearch(event);

			event.stopPropagation();
			event.stopImmediatePropagation();
			event.preventDefault();

			return false;
		});

		$('#check_all_result').on('change, ifToggled', function(){
			$('#search_result').find(':checkbox')
				.prop('checked', $(this).prop('checked'))
				.iCheck('update')
			;
			$(this).prop('checked')
				?$('#search_result .searched_row').addClass('selected')
				:$('#search_result .searched_row').removeClass('selected')
			;
		});

		this.$dateSelect = $('#date_select');
		Project.DateHelper.init(this.$dateSelect);

		$('#source_name').autocomplete({
			source		: '/sources/filter?minlen=3',
			minLength	: 3,
			select		: function(event, ui) {
				Project.Utils.debug(ui);
				//$('#source_id').val(ui.item.id);
				//self.getSourceDataHtml(ui.item.id);
			},
			search		: function( event, ui ) {
				$(this).addClass('autocomplete-loading');
			},
			response		: function( event, ui ) {
				$(this).removeClass('autocomplete-loading');
			},
			paramName	: 'letters',
			parameters	: ''
		});

		this.$searchContainerDiv	= $('#crm_search');
		this.$resultDiv				= $('#search_result');

		this.initTranslation();
		this.initExports();
	},
	/**
	 * Display search indicator and shadow layer
	 */
	showSearchIndicator	: function(){
		Project.WM.showModalLoading();
		this.$searchDiv.show();
	},
	/**
	 * Hide search indicator and shadow layer
	 */
	hideSearchIndicator	: function(){
		Project.WM.hideModalLoading();
		this.$searchDiv.hide();
	},
	showMiniIndicator	: function(){
		this.$miniIndicator = $('<li class="search-indicator-tab"><a><img src="/atlant/img/loaders/default.gif" /></a></li>');
		this.$resultDiv.find('.result-group-tabs').append(this.$miniIndicator);
	},
	hideMiniIndicator	: function(){
		if (this.$miniIndicator) this.$miniIndicator.remove();
	},
	startAjaxSearch		: function(event) {
		let params		= $('#search_form').serializeArray(),
			paramPairs	= [],
			self		= this//,
			//$exportButtons = $('#export_buttons')
		;
		//if ($exportButtons.is(':hidden')) $exportButtons.show();
		$('#result_statistics_chart, #result_statistics_avg ')
			.fadeOut('normal', () =>
				$(
					'#result_statistics_chart, ' +
					'#result_statistics_avg .j-c-search__result_statistics_avg_value, ' +
					'#result_statistics_avg .j-c-search__result_statistics_count_value, ' +
					'#result_statistics_avg .j-c-search__result_statistics_days_value, ' +
					'#result_statistics_avg .j-c-search__result_total_value,' +
					'#result_statistics_avg .j-c-search__result_total_days_value,' +
					'#result_statistics_avg .j-c-search__result_total_avg_value'
				).html(''))
			.parent().removeClass('hidden');
		$('#search_result').html('');
		$('#result_messages').children().addClass('hidden');
		this.showSearchIndicator();

		$(params).each(function(index, item){
			paramPairs.push(item.name + '=' + item.value);
		});

		this.searchStartTime	= Date.now();
		this.firstResponse		= true;
		ajax('base', 'search', [ paramPairs.join('|') ],
			/**
			 * @param {string} response
			 * @param {Ajax} xmlhttp
			 */
			function(response, xmlhttp){
				var searchKey = response;
				Project.Utils.debug('. {startAjaxSearch} ajax transporter: ', xmlhttp);
				Project.Utils.debug('. {startAjaxSearch} returned search key: ', searchKey);

				if (xmlhttp.status >= 300) {
					Project.WM.renderError(response, 'Error during sending search');
				}
				if (!searchKey.match(/[0-9a-f]{20}/i)) {
					Project.WM.renderError('Invalid search key: ' + searchKey);
					throw new Error('. {startAjaxSearch} Invalid search key!');
				}

				self.startResultPolling(searchKey);
			}

		);
	},
	/**
	 * Start polling for available results
	 *
	 * @param {String} searchKey
	 */
	startResultPolling	: function(searchKey) {
		let self = this,
			intervalID,
			pollingInterval = 3000
		;
		this.waitingForSearchResponse = false;
		this.firstResponse = true;
		$('.no-results').hide();

		intervalID = window.setInterval(function(){
			if (self.waitingForSearchResponse) {
				try {
					if(self.checkSearchTimeout()){
						window.clearInterval(intervalID);
						console.error('.Search response wait timeout (start time: ', self.searchStartTime, '; timeout: ', self.searchTimeout, ')');
					} else {
						Project.Utils.debug('. {startResultPolling} There\'s another request in progress - waiting for another ' + pollingInterval + ' ms');
					}
				} catch (e){
					console.error('.Bug? Waiting for response although no search in progress...');
					self.waitingForSearchResponse = false;
				}
			} else {
				self.waitingForSearchResponse = true;
				self.checkAvailableResult(searchKey, intervalID);
			}
		}, pollingInterval);
		Project.Utils.debug('.Search result checking interval id:', intervalID);
	},
	/**
	 * Check for wait timout
	 * @returns {boolean}
	 */
	checkSearchTimeout	: function(){
		if (this.searchStartTime === -1) throw new Error("checkSearchTimeout: There's no search in progress!");
		return (this.searchStartTime + this.searchTimeout < Date.now());
	},
	/**
	 * Asynchronously check for available result
	 *
	 * @param {String}  searchKey
	 * @param {Number}  intervalID
	 */
	checkAvailableResult: function(searchKey, intervalID){
		var self= this
		;
		Project.Utils.debug('. {checkAvailableResult} Checking search ' + searchKey + '; intervalID: ' + intervalID);
		$.ajax({
			url			: '/api/base/isResultAvailable/' + searchKey + '/',
			data		: {},
			complete	:
				/**
				 * @param {XMLHttpRequest}  jqXHR     The Ajax object from the respons
				 * @param {String}          textStatus  Status
				 */
				function(jqXHR, textStatus){
					let response = jqXHR.responseText;
					self.waitingForSearchResponse = false;
					let statusCode		= jqXHR.status,
						jqXHRRequest	= jqXHR.transport,
						statusCategory	= Math.floor(statusCode / 100)
					;
					const $exportButtons = $('#export_buttons'),
						  $searchResult = $('#search_result')
					;

					Project.Utils.debug('.. {checkAvailableResult} HTTP status: ', statusCode);
					Project.Utils.debug('.. {checkAvailableResult} HTTP status category: ', statusCategory);

					if (statusCode === 206) {
						self.appendSearchResult(response);
						return;
					} else if (statusCode === 202) {
						// keep waitin'
					} else if (statusCode === 200) {
						Project.Utils.debug('Done.');
						window.clearInterval(intervalID);
						self.hideMiniIndicator();
						self.hideSearchIndicator();
						Project.Utils.debug('. {checkAvailableResult} Cleared interval');

						self.appendSearchResult(response);

						if ($exportButtons.is(':hidden') && $searchResult.find(':checkbox').length) $exportButtons.show();

						self.searchStartTime = -1;
						if ($searchResult.children().length === 1) $('.no-results').removeClass('hidden');

						Project.Utils.debug('. {checkAvailableResult} Search finished');
						self.drawStatChart();
						return;
					} else if (statusCode === 503) {
						Project.Utils.debug('Error - no service!');
						window.clearInterval(intervalID);
						self.hideMiniIndicator();
						self.hideSearchIndicator();
						Project.Utils.debug('. {checkAvailableResult} Cleared interval');

						self.searchStartTime = -1;
						$('.no-service').removeClass('hidden');

						Project.Utils.debug('. {checkAvailableResult} Search finished');
						return;
					} else if (statusCode === 522) {
						Project.Utils.debug('Done - timeout!');
						window.clearInterval(intervalID);
						self.hideMiniIndicator();
						self.hideSearchIndicator();
						Project.Utils.debug('. {checkAvailableResult} Cleared interval');

						self.searchStartTime = -1;
						$('.search-timeout').removeClass('hidden');

						Project.Utils.debug('. {checkAvailableResult} Search finished');
						return;
					}

					if (self.firstResponse && statusCode !== 202) {
						self.hideSearchIndicator();
						if (statusCode !== 200) self.showMiniIndicator();
						self.firstResponse = false;
						return;
					}

					if (statusCategory === 5 || statusCategory === 4) {
						Project.WM.renderError(response, 'Error during request');
						window.clearInterval(intervalID);
					}

				}
		});
	},
	/**
	 *
	 * @param {string} response
	 */
	appendSearchResult	: function(response){
		let $response			= $(response),
			$respResultDiv		= $response.find('#search_result'),
			$respMsgDiv			= $response.find('.message'),
			$respNewsStats		= $response.find('.j-c-search-result__total-count-data'),
			$messageDiv			= this.$resultDiv.find('.message'),
			$resultTabs			= this.$resultDiv.find('.nav-tabs'),
			$resultTabPages		= this.$resultDiv.find('.tab-content')
		;

		this.totalCount	= $respNewsStats.data('value');
		this.totalDays	= $respNewsStats.data('total-days');

		this.$searchContainerDiv.find('form input[name=_token]').each(function(){
			let $this = $(this);
			$response.find('[name="' + $this.attr('name') + '"]').attr({
				checked	: $this.attr('checked'),
				value	: $this.attr('value'),
				selected: $this.attr('selected')
			});
		});
		Project.Utils.debug('. {appendSearchResult} appending search result: ', $respResultDiv, ' to ', this.$resultDiv);
		Project.Utils.debug('. {appendSearchResult} tab container: ', this.$resultDiv.find('.result-group-tabs'));
		Project.Utils.debug('. {appendSearchResult} children count: ', this.$resultDiv.find('.result-group-tabs').children().length);
		if(this.$resultDiv.find('.result-group-tabs').children().length > 0) {
			let $tabs			= $respResultDiv.find('.nav-tabs li'),
				$responsePages	= $respResultDiv.find('.tab-pane'),
				$existingTabs	= $resultTabs.find('li')
			;

			Project.Utils.debug('. {appendSearchResult} found result tabs: ', $tabs);
			Project.Utils.debug('. {appendSearchResult} found result pages: ', $responsePages);

			$tabs.each(function(index, tab){
				let $tab = $(tab);
				if ($existingTabs.index('[data-newsgroup=' + $tab.data('newsgroup') + ']') === -1) {
					$resultTabs.append($tab);
					$resultTabPages.append($responsePages.eq(index));
				}
			});

		} else {
			Project.Utils.debug('. {appendSearchResult} no existing children found.');
			this.$resultDiv.append($respResultDiv.children());
			this.$resultDiv.find('.result-group-tabs li:first-child a').click();
		}
		$messageDiv.after($respMsgDiv);

		Project.WM.initPlugins(this.$resultDiv);

		this.initSearchResult();
	},
	initSearchResult: function(){
		let rb = $('#resultBegin');
		if (rb.length) rb.get(0).scrollIntoView();

		this.lazyLoadLeads();
		page_content_onresize();

		let $resultGroups = this.$resultDiv.find('.result-group-tabs');

		if (!$resultGroups.find('.active').length) {
			$resultGroups.find('a[role=tab]').first().tab('show');
		}
	},
	drawStatChart	: function(base = '#crm_search'){
		Project.Utils.debug(' {drawStatChart} Drawing result statistics chart...');
		let numberFormatter = new Intl.NumberFormat(Project.language),
			newsData = []
		;

		$(base + ' [itemscope][itemtype$=NewsArticle]').each((i, elem) => {
			newsData.push({
				date	: $(elem).find('[itemProp=datePublished]').attr('content'),
				title	: $(elem).find('[itemProp=headline]').text().trim(),
				source	: $(elem).find('[itemProp=publisher]').text().trim(),
				language: $(elem).find('[itemProp=inLanguage]').attr('content'),
			});
		});

		var chartData	= _(newsData)
			.groupBy((rec) => rec.date.split(' ')[0])
			.map((recs, date) => new Object({ date: date, newsCount: recs.length }))
			.sortBy('date')
			.value()
		;
		if(chartData.length === 0) return;

		var totalNews	= chartData.length > 1
				?_(chartData)
					.pluck('newsCount')
					.sum()
				:chartData[0].newsCount,
			daysCount	= (chartData.length),
			average		= daysCount > 0?Math.round(totalNews / daysCount):-1,
			//totalCount	= $('.j-c-search-result__total-count-data').data('value'),
			//totalDays	= $('.j-c-search-result__total-count-data').data('total-days'),
			totalAvg	= this.totalDays?Math.round(this.totalCount / this.totalDays):-1
		;
		Project.Utils.debug(' {drawStatChart} chartData:', chartData);

		Project.Utils.debug(' {drawStatChart} totalCount: ', this.totalCount);
		Project.Utils.debug(' {drawStatChart} totalDays: ', this.totalDays);
		Project.Utils.debug(' {drawStatChart} totalAvg: ', totalAvg);

		$('.j-c-search__result_statistics_avg_value'	).text(average == -1 || isNaN(average)?'n/a':numberFormatter.format(average));
		$('.j-c-search__result_statistics_count_value'	).text(numberFormatter.format(totalNews));
		$('.j-c-search__result_statistics_days_value'	).text(numberFormatter.format(daysCount));
		$('.j-c-search__result_total_value'				).text(numberFormatter.format(this.totalCount));
		$('.j-c-search__result_total_days_value'		).text(numberFormatter.format(this.totalDays));
		$('.j-c-search__result_total_avg_value'			).text(totalAvg == -1 || isNaN(totalAvg)?'n/a':numberFormatter.format(totalAvg));

		if (chartData.length > 2 || (chartData.length == 2 && this.totalCount == totalNews)) {
			AmCharts.makeChart('result_statistics_chart', {
				type           : "serial",
				categoryField  : "date",
				dataDateFormat : "YYYY-MM-DD",
				startDuration  : 3,
				categoryAxis   : {
					autoRotateAngle : 45,
					parseDates      : true,
					firstDayOfWeek  : 1
				},
				chartCursor    : {
					enabled : true
				},
				chartScrollbar : {
					enabled : true
				},
				graphs         : [
					{
						id         : "AmGraph-1",
						title      : Project.Messages.get('NO_OF_RESULTS'),
						valueAxis  : "ValueAxis-1",
						valueField : "newsCount",
						xAxis      : "ValueAxis-1"
					}
				],
				valueAxes      : [
					{
						id             : "ValueAxis-1",
						firstDayOfWeek : 1,
						title          : Project.Messages.get('NO_OF_RESULTS')
					}
				],
				legend         : {
					enabled          : false,
					useGraphSettings : true
				},
				/*			titles			: [
				 {
				 id	: "Title-1",
				 size: 15,
				 text: "Találatok megoszlása"
				 }
				 ],*/
				dataProvider   : chartData
			});

			$('#result_statistics_chart').show();
		}

		$('#result_statistics_avg').show();
	},

	initTranslation	: function(base = '#crm_search') {
		var $base = $(base)
		;

		$base.on('click', '.b-translate-enabler', function(){
			$(this).closest('.sr-item').find('.search-result-controls').removeClass('hidden');
			$(this).hide();
		});

		$base.on('click', '.b-translate', function(ev) {
			var $translationButton		= $(this),
				targetLang				= $translationButton.closest('.search-result-controls').find('select.target-language').val(),
				$loadingContainer		= $("#translated_loading"),
				$resultContainer		= $translationButton.closest('.sr-item'),
				id						= $resultContainer.data('uid'),
				translatedId			= 'translated_' + targetLang + '_' + id,
				$translationContainer	= $('#' + translatedId),
				translateType			= $translationButton.data('translatetype'),
				translateMethod			= translateType == 'search'?'remoteTranslateSearchResult':'remoteTranslate'
			;

			($translationContainer.length != 0)
				?$translationContainer.html('')
				:$translationContainer = $('<div class="translated" id="' + translatedId + '" />')
			;

			$translationButton.closest('.search-result-controls').before($translationContainer);
			$translationContainer.append($loadingContainer);
			$loadingContainer.show();//.get(0).scrollIntoView();
			Project.WM.showModalLoading();
			
			ajax('news_summary', translateMethod, [ id, targetLang ], function(response) {
				$.get('/log-machine-translate/' + id + '/');

				Project.WM.hideModalLoading();
				$loadingContainer.hide();

				$('#searchDiv').after($loadingContainer);
				$translationContainer.html(response).get(0).scrollIntoView();
			}, function(errorThrown, textStatus, jqXHR){
				Project.WM.hideModalLoading();
				$loadingContainer.hide();
				Project.WM.renderError(Project.Messages.get('ERROR') + ': ' + errorThrown, Project.Messages.get('ERROR'))
			});
		});
		Project.Utils.debug(' ....Machine translation initialized')
	},
	lazyLoadLeads	: function(){
		var titleElem,
			loadQ = Nibble.loadQ.create();

		$('.lazyload-lead').each(function(index, elem){
			titleElem = $(this).closest('.searched_row').find('.searched_title');
			loadQ.add(titleElem.attr('href').replace(/\/view\/pd/, '/view/pd/lead') + '&lead=1', function(data){
				$(elem).html(data);
			});
			$(this).html('Loading...');
		});

		if (loadQ.getSize() > 0 && !document.location.hostname.match(/dev\.local/)) {
			loadQ.startQ(5);
		}
	},
	initSettings: function()	{
		Project.Utils.debug('init settings');

		$('select').on('change', function(){
			var setting = $(this).attr('id');
			ajax("base", "setSetting", [ $(this).val(), setting ], function(response, xmlhttp){
				Project.Utils.debug(response);
				if(setting == 'actualLanguage')	{
					document.location = window.location.href.split('?')[0];
				}					
				if (setting == 'skin') document.location.reload();
			});
		});
		
		$('input[type="checkbox"]').on('change', function () {
			var val = $(this).is(':checked') ? 1 : 0;

			var name = $(this).attr('name');
			
			if(name == 'terminologyManager')	{
				if (val) {
					$('#terminologyManagerColoring').parents('.form-group').removeClass('hide');
					$('#terminologyManagerMode').parents('.form-group').removeClass('hide');
					$('#terms-panel').removeClass('hide');
				} else {
					$('#terminologyManagerColoring').parents('.form-group').addClass('hide');
					$('#terminologyManagerMode').parents('.form-group').addClass('hide');
					$('#terms-panel').addClass('hide');
				}
			}			
			
			// maximum 4 chart
			var counter = 0;
			var stats = ['dashboardNewsCount', 'dashboardCountry', 'dashboardMapType', 'dashboardSources', 'dashboardWordCloud', 'dashboardSentiment'];			
			if(stats.indexOf(name) >=  0)	{
				var i;
				for(i in stats)	{
					if($('#'+stats[i]).is(':checked'))	{
						counter++;
					}
				}
			}
			
			/*if(counter > 4)	{
				$(this).trigger('click');
				Project.WM.renderError(Project.Messages.get('DASHBOARD_MAX_CHART'), Project.Messages.get('ERROR'));
			}
			else {*/
				if(name == 'terms_list')	{
					val = $(this).attr('value');
				}
				
				ajax("base", "setSetting", [val, $(this).attr('id')], function (response, xmlhttp) {
					Project.Utils.debug(response);
				});	
			/*}*/
		});
	}
};
